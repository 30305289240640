/**
 * Get User Details - async action creators
 * Hanlde the Pending, Fullfilled (Success), Rejected action
 *
 * @return object
 */
export function getUserDetail() {
    return {
        types: ['PROFILE_INFO_PENDING', 'PROFILE_INFO_FULFILLED', 'PROFILE_INFO_REJECTED'],
        url: 'users/profile',
    };
}
